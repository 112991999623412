<template>
  <EditorDialog
    title="Добавить новую команду"
    :max-width="720"
    :save-func="save"
    @closed="localClose"
  >
  <v-form class="pa-4" ref="form" lazy-validation>
    <v-row>
      <v-col cols="12">
        <v-text-field
          label="Название команды"
          v-model="model.name"
          :rules="validation.name"
          hide-details="auto"
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="9">
        <v-text-field
          label="Место дислокации команды"
          v-model="model.location"
          hide-details="auto"
          dense
        />
      </v-col>
      <v-col cols="3">
        <v-checkbox
          v-model="model.onlineClub"
          label="Онлайн клуб"
          hide-details="auto"
          dense
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          label="Лимит участников"
          v-model="model.limit"
          :rules="validation.limit"
          hide-details="auto"
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="model.leader"
          label="ФИО руководителя"
          hide-details="auto"
          dense
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="model.url"
          label="Ссылка на соц.сети или сайт"
          hide-details="auto"
          dense
        />
      </v-col>
      <v-col cols="12" v-if="model.teamId">
        <v-text-field
          label="Ссылка на регистраицю"
          v-model="regLink"
          readonly
          hide-details="auto"
          dense
        ></v-text-field>
      </v-col>
      <v-col>
        <!-- <v-textarea
          v-model="model.description"
          label="О команде"
          counter
          maxlength="3000"
        /> -->
        <vue-editor v-model="model.description" :editorToolbar="customToolbar"></vue-editor>
      </v-col>
    </v-row>
  </v-form>
  <v-dialog v-model="dialog.opened" width="720">
    <v-card>
      <v-card-title class="headline">{{ dialog.title }}</v-card-title>
      <v-card-text v-html="dialog.text">
      </v-card-text>
      <v-card-actions>
        <v-btn @click="closeDialog">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </EditorDialog>
</template>

<script>
import { uuid } from "vue-uuid";
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import editorDialogMixin from '@/global-components/dialog/mixins';
import scountApiInitializer from '@/api/scountApi';
import { VueEditor } from "vue2-editor";

export default {
  name: 'TeamEditorDialog',
  mixins: [editorDialogMixin],
  components: {
    VueEditor
  },

  props: {
    object: {
      type: Object,
      default: () => {
        return {
          name: undefined,
          url: undefined,
          location: undefined,
          onlineClub: false,
          leader: undefined,
          description: undefined
        }
      }
    },
    saveFunc: Function,
  },

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    return { marketingProgramStore }
  },

  computed: {
    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    scountApi() {
      if (!this.mp)
        return null;

      return scountApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },

    regLink() {
      if (!this.model || !this.model.teamId)
        return '';

      const devSlag = location.host.indexOf('.rr-dev.') > -1 || location.host.indexOf('localhost') > -1 ? '.rr-dev' : '';

      // TODO: вынести в единое место
      const pairs = [
        { marketingProgramId: 'a6a2d18f-940d-4237-b99e-ec2d81281689', domain: `https://runningmr${devSlag}.ru/` },
        { marketingProgramId: '5696b784-5be8-4f76-8e5c-df084275c27d', domain: `https://mgoprof${devSlag}.ru/` },
        { marketingProgramId: 'bec0c390-4d39-44fb-86d7-fe65c3b89883', domain: `https://mneposilam${devSlag}.ru/` },
        { marketingProgramId: 'f94c9680-ad60-4636-b9ed-f32ae5282622', domain: devSlag ? `https://shag${devSlag}.ru/` : 'https://shag.scount.pro/' },
        { marketingProgramId: 'f3a88565-1657-4779-ae47-b3cc0287fc13', domain: devSlag ? `https://farmeco${devSlag}.ru/` : 'https://фармчемпионат.рф/' },
        { marketingProgramId: 'af0f9923-f448-413c-ba01-e09cebcb95f6', domain: devSlag ? `https://auchan${devSlag}.ru/` : 'https://auchan.scount.pro/' },
        { marketingProgramId: '37f15c6d-308f-4fb8-bc45-50a1634439b7', domain: devSlag ? `https://superliga78${devSlag}.ru/` : 'https://superliga78.scount.pro/' },
        { marketingProgramId: '42cce763-abc3-490e-a461-1568d354951d', domain: devSlag ? `https://expo${devSlag}.ru/` : 'https://expo.scount.pro/' },
        { marketingProgramId: 'dfeaffb5-56a3-4135-8345-3625d24c6b4c', domain: devSlag ? `https://rrexpo${devSlag}.ru/` : 'https://rrexpo.scount.pro/' },
        { marketingProgramId: '4d008edc-17d1-4669-8bd8-10221c98586a', domain: devSlag ? `https://severstal${devSlag}.ru/` : 'https://severstal.scount.pro/' },
        { marketingProgramId: '537acce1-f980-4cdf-b5fd-850a16be085b', domain: devSlag ? `https://demo${devSlag}.ru/` : 'https://demo.scount.pro/' },
        { marketingProgramId: 'bf698762-9d3d-4b93-acde-8bb534dc56cb', domain: devSlag ? `https://sitronics${devSlag}.ru/` : 'https://sitronics.scount.pro/' },
      ];

      const host = pairs.find(x => x.marketingProgramId === this.mp.marketingProgramId).domain;

      return `${host}?t=${btoa(this.model.teamId)}`;
    },
  },

  data: () => ({
    customToolbar: [
      ["bold",{ list: "ordered"}, {list: "bullet" }, "link"],
    ],
    dialog: {
      opened: false,
      title: undefined,
      text: undefined
    },
    saving: false,
    validation: {
      name: [
        value => !!value || 'Обязательное поле.',
        value => (value && value.length <= 256) || 'Не более 256 символов.',
      ],
      url: [
        value => !!value || 'Обязательное поле.',
        value => (value && value.length <= 256) || 'Не более 256 символов.',
      ],
      limit: [
        value => (!value || !isNaN(parseInt(value))) || 'Требуется целое число.',
      ]
    },
  }),

  methods: {
    async save() {
      this.saving = true;
      
      const valid = await this.$refs.form.validate();

      if (!valid)
        return;

      this.saveTeam(this.model);
    },

    localClose() {
      const saving = this.saving;
      this.saving = false;

      if (saving)
        return;

      this.close();
    },

    async saveTeam(team) {
      if (team.teamId) {
        await this.updateTeam(team);
      } else {
        await this.createTeam(team);
      }
    },

    async createTeam(team) {
      var teamId = uuid.v4();
      const request = {
        name: team.name,
        limit: team.limit,
        location: team.location,
        onlineClub: team.onlineClub,
        leader: team.leader,
        url: team.url,
        description: team.description,
        attributes: []
      };

      const response = await this.scountApi.createTeam(teamId, request);
      if (response.success) {
        this.saveFunc({ success: true });
        this.close();
      } else {
        this.saveFunc({ error: response.error });
      }
    },

    async updateTeam(team) {
      const request = {
        name: team.name,
        limit: team.limit,
        attributes: []
      };

      const response = await this.scountApi.updateTeam(team.teamId, request);
      if (response.success) {               
        this.saveFunc({ success: true });
        this.close();
      } else {
        this.saveFunc({ error: response.error });
      }
    },

    changeDesktopImage(file) {
      this.model.desktopImageFile = file;
    },

    changeMobileImage(file) {
      this.model.mobileImageFile = file;
    },

    getUploadAfterSaveText(resp) {
        const e = resp.error;
        if (!e) return 'Произошла ошибка';
        if (e.status === 413) return 'Фото не было загружено. Превышен максимальный размер изображения';
        if (e.status === 400 && e.responseData && e.responseData.errors && e.responseData.errors.File) {
          const errFile = e.responseData.errors.File;
          const max = errFile.find(x => x.startsWith('Maximum '));
          if (max)
            return 'Фото не было загружено. Превышен максимальный размер изображения.';
        }

        return e.detail;
    },

    showError(title, text) {
      this.dialog.title = title;
      this.dialog.text = text;
      this.dialog.opened = true;
    },

    hideError() {
      this.dialog.title = undefined;
      this.dialog.text = undefined;
      this.dialog.opened = false;
    },

    openDialog(title, text, actionDialog, actionName) {
      this.dialog.title = title;
      this.dialog.text = text;
      this.dialog.actionDialog = actionDialog;
      this.dialog.actionName = actionName;
      this.dialog.opened = true;
    },

    closeDialog() {
      this.dialog.title = undefined;
      this.dialog.text = undefined;
      this.dialog.actionDialog = undefined;
      this.dialog.actionName = undefined;
      this.dialog.opened = false;
    },
  }
}
</script>