import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from "./stores";
// import store from './store/marketingProgram'
import { createPinia, PiniaVuePlugin } from 'pinia'
// import VueCompositionAPI from '@vue/composition-api'
import Notifications from 'vue-notification'
import formatDate from "@/filters/formatDateTime";
import cropText from "@/filters/cropText";
import msToTime from "@/filters/msToTime";
import upperCase from '@/filters/upperCase';
import Moment from 'moment'
// don't forget to import CSS styles
import * as Components from './global-components'
import "./assets/icon/style.css";
import "./assets/global.scss";
import Keycloak from "keycloak-js";

Vue.config.productionTip = false

Vue.use(PiniaVuePlugin)
Vue.use(Notifications)
// Vue.use(VueCompositionAPI)

Vue.filter('formatDate', formatDate);
Vue.filter('cropText', cropText);
Vue.filter('msToTime', msToTime);
Vue.filter('upperCase', upperCase);

Vue.filter('formatDateTimeRus', function(value) {
  if (value) {
    return Moment.utc(String(value)).local().format('DD.MM.YYYY HH:mm');
  }
});

Vue.filter('formatDateRus', function(value) {
  if (value) {
    return Moment.utc(String(value)).local().format('DD.MM.YYYY');
  }
});

for (let component in Components) {
  Vue.component(component, Components[component]);
}

const pinia = createPinia();

function getKeycloakUrl(uri) {
  let keycloakBaseUrl = window.location.origin;
  if (window.location.host === 'localhost:8080')
    keycloakBaseUrl = 'https://scount-admin.rr-dev.ru';
  const defaultUrl = `${keycloakBaseUrl}/auth`;
  if (uri) {
    if (uri.indexOf("/") === 0) return `${keycloakBaseUrl}${uri}`;
    return uri;
  }
  return defaultUrl;
}


function init() {
  const w = window;
  const openId = w.ADMIN_WEB_CLIENT_APP_SETTINGS["openId"];

  const initOptions = {
    url: getKeycloakUrl(openId.uri),
    realm: openId.realm,
    clientId: openId.clientId,
  }

  const keycloak = new Keycloak(initOptions);

  keycloak.init({
    redirectUri: window.location.href,
    scope: "openid profile email",
    flow: "implicit",
    onLoad: "login-required",
  }).then(() => {
    const w = window;
    w.ADMIN_WEB_CLIENT_APP = new Vue({
      vuetify,
      router,
      store,
      pinia,
      render: h => h(App, { props: { keycloak: keycloak } })
    }).$mount('#app')
  }).catch(() => { });
}

init();