<template>
  <div>
    {{ formattedPoints }} {{ pointsLabel }}
    за
    <span style="color: #49ae4d">
      {{ formattedSteps }} {{ stepsLabel }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'OperationStepParticipant',

  props: {
    sourceId: String,
    attributes: Object,
  },

  computed: {
    formattedPoints() {
      return Math.round(this.attributes.AccruedPoints);
    },
    formattedSteps() {
      return Math.round(this.attributes.StepsCount);
    },
    pointsLabel() {
      return this.getWordForm(this.formattedPoints, ['балл', 'балла', 'баллов']);
    },
    stepsLabel() {
      return this.getWordForm(this.formattedSteps, ['шаг', 'шага', 'шагов']);
    }
  },

  methods: {
    getWordForm(number, forms) {
      number = Math.abs(number) % 100;
      const n1 = number % 10;
      if (number > 10 && number < 20) return forms[2];
      if (n1 > 1 && n1 < 5) return forms[1];
      if (n1 == 1) return forms[0];
      return forms[2];
    }
  }
}
</script>