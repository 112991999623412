<template>
  <div>
    <v-progress-linear v-if="!sectionStatus" indeterminate value="15"></v-progress-linear>
    <div v-else class="market-section p-8">
      <v-expansion-panels class="mb-6" v-model="panels" multiple>
        <v-expansion-panel
          v-for="(item) in items"
          :key="item.id"
          class="mb-2"
          :readonly="item.children ? item.children.length < 1 : true"
        >
          <v-expansion-panel-header :class="{ 'none': item.children ? item.children.length < 1 : null }">
            <div class="market-section__header">
              <div class="market-section__header-left">
                <p class="market-section__header-title">{{ item.name }}</p>
                <span class="market-section__header-id">ID:{{ item.categoryTreeNodeId }} || order: {{ item.order }}</span>
              </div>
              <div class="market-section__header-right">
                <div class="market-section__header-img mr-4">
                  <img v-if="item.imageUrl" :src="item.imageUrl" alt="">
                  <img v-else src="img/empty.png" alt="">
                </div>
                <v-icon @click.stop="dialogSectionOpen(item, type = 'edit', isChildren = true)" class="pa-2">mdi-pencil</v-icon>
                <v-icon @click.stop="viewItem(item)" class="pa-2">mdi-eye</v-icon>
                <v-icon @click.stop="dialogDeleteOpen(item)" class="mr-4 pa-2">mdi-delete</v-icon>
                <v-icon class="pa-2" @click.stop="moveUp(item)">mdi-arrow-up</v-icon>
                <v-icon class="mr-4 pa-2" @click.stop="moveDown(item)">mdi-arrow-down</v-icon>
                <v-icon class="pa-2" @click.stop="dialogSectionOpen(item, type = 'create')" color="#FF8233">mdi-plus-box</v-icon>

              </div>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <!-- <v-progress-linear indeterminate absolute top value="15"></v-progress-linear> -->
            <v-expansion-panels class="children">
              <v-expansion-panel
                v-for="item in item.children" :key="item.id" :readonly="item.children ? item.children.length < 1 : true">
                <v-expansion-panel-header :class="{ 'none': item.children ? item.children.length < 1 : null }">
                  <div class="market-section__header">
                    <div class="market-section__header-left">
                      <p class="market-section__header-title">{{ item.name }}</p>
                      <span class="market-section__header-id">ID:{{ item.categoryTreeNodeId }}</span>
                    </div>
                    <div class="market-section__header-right">
                      <div class="market-section__header-img mr-4">
                        <img v-if="item.imageUrl" :src="item.imageUrl" alt="">
                        <img v-else src="img/empty.png" alt="">
                      </div>
                      <v-icon @click.stop="dialogSectionOpen(item, type = 'edit', isChildren = true)" class="pa-2">mdi-pencil</v-icon>
                      <v-icon @click.stop="viewItem(item)" class="pa-2">mdi-eye</v-icon>
                      <v-icon @click.stop="dialogDeleteOpen(item)" class="mr-4 pa-2">mdi-delete</v-icon>
                      <v-icon class="pa-2">mdi-arrow-up</v-icon>
                      <v-icon class="mr-4 pa-2">mdi-arrow-down</v-icon>
                      <v-icon class="pa-2" @click.stop="dialogSectionOpen(item, type = 'create')" color="#FF8233">mdi-plus-box</v-icon>
                    </div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-expansion-panels class="children" >
                    <v-expansion-panel
                      v-for="item in item.children"
                      :key="item.id"
                      readonly
                    >
                      <v-expansion-panel-header :class="{ 'none': item.children ? item.children.length < 1 : null }">
                        <div class="market-section__header">
                          <div class="market-section__header-left">
                            <p class="market-section__header-title">{{ item.name }}</p>
                            <span class="market-section__header-id">ID:{{ item.categoryTreeNodeId }}</span>
                          </div>
                          <div class="market-section__header-right">
                            <v-icon @click.stop="dialogSectionOpen(item, type = 'edit', isChildren = false)" class="pa-2">mdi-pencil</v-icon>
                            <v-icon @click.stop="viewItem(item)" class="pa-2">mdi-eye</v-icon>
                            <v-icon @click.stop="dialogDeleteOpen(item)" class="mr-4 pa-2">mdi-delete</v-icon>
                            <v-icon class="pa-2">mdi-arrow-up</v-icon>
                            <v-icon class="pa-2">mdi-arrow-down</v-icon>
                          </div>
                        </div>
                      </v-expansion-panel-header>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-row>
        <v-col>
          <v-btn large @click="dialogSectionOpen">
            <v-icon left>mdi-plus</v-icon>
              Добавить раздел
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      v-model="dialogSection"
      max-width="720"
    >
    <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Добавить раздел
        </v-card-title>
        <v-form
          v-model="valid"
          class="pa-6 pb-0"
          ref="form"
          lazy-validation
        >
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="name"
                label="Название"
                dense
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="order"
                label="Номер"
                dense
              />
            </v-col>
            <v-col cols="12" v-if="state === 'edit' && isUploadImage">
              <upload-images
                @changeFile="changeDesktopImage"
                @fileInputClick="clickImage"
                :imageSelected="imageDesktopSelected"
                width="241"
                height="128"
                title="Иллюстрация(необязательно)"
                accept=".png"
                imageSize="1920x500"
              />
            </v-col>
          </v-row>
        </v-form>
        <v-card-actions class="pa-6">
          <v-spacer></v-spacer>
          <v-btn @click="dialogSection = false">Отмена</v-btn>
          <v-btn
            color="#FF8233"
            :dark="valid"
            :disabled="!valid"
            @click="saveSection"
          >
          <!-- <v-progress-circular
            indeterminate
            color="white"
            :size="20"
            width="2"
          /> -->
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogDelete"
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h6">
          Удалить раздел?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="dialogDelete = false"
          >
            Отменить
          </v-btn>

          <v-btn
            color="#FF8233"
            dark
            @click="deleteItem"
            class="ml-4"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import uploadImages from '@/components/uploadImages.vue';
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import { useKeycloakStore } from '@/stores/keycloakStore';
import marketApiInitializer from '@/api/marketApiNoMp';
import { uuid } from "vue-uuid";

  export default {
    name: 'marketSections',
    components: { uploadImages },
    data: () => ({
      dialogSection: false,
      dialogDelete: false,
      valid: true,
      imageDesktopSelected: null,
      imageDesktopFile: null,
      imageMobileSelected: null,
      imageMobileFile: null,
      panels: [],
      items: [],
      name: '',
      order: 0,
      categoryId: null,
      sectionStatus: false,
      state: '',
      isUploadImage: true
    }),
    setup() {
      const marketingProgramStore = useMarketingProgramStore()
      const keycloakStore = useKeycloakStore()
      return { marketingProgramStore, keycloakStore }
    },
    methods: {
      async getCategoryTrees() {
        const response = await this.marketApi.getCategoryTrees()
        if (response.status == 200) {
          this.items = response.data
          this.sectionStatus = true
        } else {
          this.sectionStatus = false
        }
        console.log(response);
      },
      async createCategoryTrees() {
        const id = this.categoryId ? this.categoryId : null
        const data = {
          parentId: id,
          categoryTreeNodeId: uuid.v4(),
          name: this.name,
          order: this.order ? this.order : 0
        }
        await this.marketApi.createCategoryTrees(data)
        .then (() => {
          this.getCategoryTrees()
        })
      },
      saveSection() {
        if (this.state === 'edit') {
          this.editItem()
        } else {
          this.createCategoryTrees()
        }
        this.dialogSection = false
        this.categoryId = null
      },
      dialogSectionOpen(item, type, isChildren) {
        console.log('dialogSectionOpen', isChildren);
        this.state = type
        this.isUploadImage = isChildren
        if (item.categoryTreeNodeId && this.state === 'edit') {
          this.categoryId = item.categoryTreeNodeId
          this.marketApi.getCategoryTreeNode(item.categoryTreeNodeId)
          .then (() => {
            this.name = item.name
            this.order = item.order
            this.imageDesktopSelected = item.imageUrl
            this.dialogSection = true
          })
        } else {
          this.categoryId = item.categoryTreeNodeId
          this.name = ''
          this.order = 0
          this.imageDesktopSelected = null
          this.dialogSection = true
        }
      },
      moveUp(item) {
        console.log('moveUp', item);
        const data = {
          order: item.order > 0 ? item.order - 1 : 0,
          name: item.name
        }
        this.marketApi.updateCategoryTreeNode(item.categoryTreeNodeId, data)
        .then (() => {
          this.getCategoryTrees()
        })
      },
      moveDown(item) {
        console.log('moveDown', item);
        const data = {
          order: item.order + 1,
          name: item.name
        }
        this.marketApi.updateCategoryTreeNode(item.categoryTreeNodeId, data)
        .then (() => {
          this.getCategoryTrees()
        })
      },
      dialogDeleteOpen (item) {
        this.dialogDelete = true
        this.categoryId = item.categoryTreeNodeId
      },
      clickImage(fileUploadId) {
        document.getElementById(fileUploadId).click();
      },
      changeDesktopImage(imageFile, file) {
        if(imageFile) {
          this.imageDesktopSelected = imageFile
          this.imageDesktopFile = file
        }
      },
      changeMobileImage(imageFile, file) {
        if(imageFile) {
          this.imageMobileSelected = imageFile
          this.imageMobileFile = file
        }
      },
      async editItem () {
        if (this.categoryId !== null) {
          const data = {
            name: this.name,
            order: this.order
          }
          const ImageKey = uuid.v4()
          const categoryTreeNodeId = this.categoryId
          if (this.imageDesktopFile) {
            await this.marketApi.uploadCategoryImage(categoryTreeNodeId, ImageKey, this.imageDesktopFile)
          }
          const response = await this.marketApi.updateCategoryTreeNode(categoryTreeNodeId, data)
          console.log('editItem', response);
          if (response.status == 204) {
            this.getCategoryTrees()
            this.categoryId = null
          }
        }
      },
      viewItem (item) {
        console.log(item);
      },

      async deleteItem () {
        const response = await this.marketApi.deleteCategoryTreeNode(this.categoryId)
        if (response.status == 204) {
          this.getCategoryTrees()
          this.categoryId = null
          this.dialogDelete = false
        }
      },

      addItem (item, i) {
        const child = {
          id: uuid.v4(),
          name: 'Название',
          children1: []
        }
        item.children.push(child);
        this.panels = [i]
        console.log(i);
      },
      addItemChild (item) {
        console.log('addItemChild', item);
        const child = {
          id: uuid.v4(),
          name: 'Название',
        }
        item.children1.push(child);
        console.log(item);
      }
    },
    computed: {
      mp() {
        if (this.marketingProgramStore.isInitialized === false)
          return null;

        return this.marketingProgramStore;
      },

      marketApi() {
        return marketApiInitializer
      },

      kl() {
        return this.keycloakStore;
      },
    },
    mounted() {
      this.getCategoryTrees();
    },
  }
</script>

<style lang="scss">

.v-expansion-panels.children .v-expansion-panel {
  background-color: rgba(248, 248, 248, 1) !important;
  &::before {
    box-shadow: none !important;
  }
}
.v-expansion-panels.children1 .v-expansion-panel {
  background-color: rgba(255, 255, 255, 1) !important;
  &::before {
    box-shadow: none !important;
  }
  .v-expansion-panel-header__icon {
    display: none
  }
  .v-expansion-panel-header {
    padding-left: 0 !important;
  }
}
.v-expansion-panel-header {
  position: relative;
  &.none {
    padding-left: 0;
    .v-expansion-panel-header__icon {
      display: none
    }
  }
}
.v-expansion-panel-content__wrap {
  padding: 0;
  padding-left: 20px;
  padding-bottom: 14px;
}
.v-expansion-panel {
  background-color: rgba(240, 240, 240, 1) !important;
}
.v-application--is-ltr .v-expansion-panel-header__icon {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 20px;
  top: 24px;
}
.v-application p {
  margin-bottom: 0;
}
.market-section {
  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 24px;
    &-title {
      font-size: 16px;
      line-height: 24px;
      margin: 0;
    }
    &-id {
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.38);
    }
    &-left {
    }
    &-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    &-img {
      width: 57px;
      height: 32px;
      img {
        max-width: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

</style>