<template>
  <div>
    <v-list v-if="mp" class="pa-0">
      <v-list-item class="pa-2" link @click="reset">
        <v-list-item-avatar>
          <v-img height="40" width="40" :src="mpLogo"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ mp.title }}</v-list-item-title>
          <v-list-item-subtitle v-text="'Маркетинговая программа'"></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import { useKeycloakStore } from '@/stores/keycloakStore';

export default {
  name: 'MarketingProgramSelector',

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    const keycloakStore = useKeycloakStore()
    return { marketingProgramStore, keycloakStore }
  },

  computed: {
    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    kl() {
      return this.keycloakStore;
    },

    mpLogo() {
      if (!this.mp.marketingProgramId)
        return null;

      switch (this.mp.marketingProgramId) {
        case 'a6a2d18f-940d-4237-b99e-ec2d81281689': return '../img/logo/logo-slp.svg';
        case '5696b784-5be8-4f76-8e5c-df084275c27d': return 'https://mgoprof.russiarunning.com/themes/MGO/favicon/apple-touch-icon.png';
        case 'bec0c390-4d39-44fb-86d7-fe65c3b89883': return 'https://mneposilam.russiarunning.com/themes/LDPR/favicon/apple-touch-icon.png';
        case 'f94c9680-ad60-4636-b9ed-f32ae5282622': return 'https://shag.russiarunning.com/themes/LDPR/favicon/apple-touch-icon.png';
        case 'f3a88565-1657-4779-ae47-b3cc0287fc13': return 'https://фармчемпионат.рф/themes/transformation/favicon/apple-touch-icon.png';
        case 'af0f9923-f448-413c-ba01-e09cebcb95f6': return 'https://auchan.scount.pro/themes/transformation/favicon/apple-touch-icon.png';
        case '37f15c6d-308f-4fb8-bc45-50a1634439b7': return 'https://superliga78.scount.pro/themes/transformation/favicon/apple-touch-icon.png';
        case '42cce763-abc3-490e-a461-1568d354951d': return 'https://expo.scount.pro/themes/transformation/favicon/apple-touch-icon.png';
        case 'dfeaffb5-56a3-4135-8345-3625d24c6b4c': return 'https://rrexpo.scount.pro/themes/rrexpo/favicon/apple-touch-icon.png';
        case '4d008edc-17d1-4669-8bd8-10221c98586a': return 'https://severstal.scount.pro/themes/severstal/favicon/apple-touch-icon.png';
        case '537acce1-f980-4cdf-b5fd-850a16be085b': return 'https://demo.scount.pro/themes/demo/favicon/apple-touch-icon.png';
        case 'bf698762-9d3d-4b93-acde-8bb534dc56cb': return 'https://sitronics.scount.pro/themes/sitronics/favicon/apple-touch-icon.png';
        default: return null;
      }
    },
  },

  methods: {
    reset() {
      if (!this.kl.isSimpleMp)
        this.mp.$reset();
    },
  },
}
</script>