<template>
  <div>
    <v-alert
      border="left"
      colored-border
      type="info"
      elevation="2"
    >
      <b>Приоритет</b> и <b>сложность</b> - одно и то же. Тренировки учитываются в первую очередь на заданиях с наивысшим приоритетом.
    </v-alert>

    <DataTable
      controller="inRoute"
      :headers="headers"
      :items="items"
      :server-items-length="total"
      sort-by="date"
      :sort-desc="true"
      search-title="Найти задание"
      :search-fields="searchFields"
      :loading="updating"
      @update:table-options="tableUpdated"
      @click:row="tableRowClicked"
    >
      <template #actions>
        <v-btn @click="exerciseEditorDialogShow = true" class="ml-3">Добавить задание</v-btn>
        <v-btn @click="exerciseFromTempalteDialogShow = true" class="ml-3">Добавить из каталога</v-btn>
      </template>

      <template v-slot:[`item.attributes`]="{item}">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              <v-icon class="mr-2" :color="item.isAutomaticAssign ? 'success' : ''">
                mdi-refresh-auto
              </v-icon>
            </span>
          </template>
          <span>Авто</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              <v-icon class="mr-2" :color="getType(item.type).color">
                {{ getType(item.type).icon }}
              </v-icon>
            </span>
          </template>
          <span>{{ getType(item.type).text }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.title`]="{item}">
        {{ item.title }}
        <div class="exercise-id">ID: {{ item.exerciseId }}</div>
      </template>
      <template v-slot:[`item.complexity`]="{item}">
        {{ item.complexity }}
      </template>
      <template v-slot:[`item.startDate`]="{item}">
        {{ item.startDate | formatDate }}
      </template>
      <template v-slot:[`item.endDate`]="{item}">
        {{ item.endDate | formatDate }}
      </template>
      <template v-slot:[`item.createdOn`]="{item}">
        {{ item.createdOn | formatDate }}
      </template>
      <template v-slot:[`item.publishedOn`]="{item}">
        {{ item.publishedOn | formatDate }}
      </template>
    </DataTable>

    <ExerciseEditorDialog
      v-if="exerciseEditorDialogShow"
      :save-func="createExercise"
      @closed="exerciseEditorDialogShow = false"
    />

    <ExerciseFromTempalteDialog
      v-if="exerciseFromTempalteDialogShow"
      :save-func="createExerciseFromTemplate"
      @closed="exerciseFromTempalteDialogShow = false"
    />

    <v-dialog v-model="dialog.opened" width="720">
      <v-card>
        <v-card-title class="headline">{{ dialog.title }}</v-card-title>
        <v-card-text v-html="dialog.text">
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeDialog">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { uuid } from "vue-uuid";
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import scountApiInitializer from '@/api/scountApi';
import ExerciseEditorDialog from './ExerciseEditorDialog.vue';
import ExerciseFromTempalteDialog from './ExerciseFromTempalteDialog.vue';
import Moment from 'moment'

export default {
  name: 'ExercisesView',
  components: { ExerciseEditorDialog, ExerciseFromTempalteDialog },

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    return { marketingProgramStore }
  },

  data: () => ({
    headers: [
      { value: 'attributes', text: 'Параметры', align: 'start', sortable: false },
      { value: 'title', text: 'Название', align: 'start', sortable: true },
      { value: 'complexity', text: 'Сложность', align: 'start', sortable: false },
      { value: 'startDate', text: 'Начало', align: 'end', sortable: true },
      { value: 'endDate', text: 'Окончание', align: 'end', sortable: true },
      { value: 'createdOn', text: 'Создан', align: 'end', sortable: true },
      { value: 'publishedOn', text: 'Опубликован', align: 'end', sortable: true, width: '150px' },
    ],
    dialog: {
      opened: false,
      title: undefined,
      text: undefined
    },
    items: [],
    total: 0,
    searchFields: [
      { key: 'title', label: 'Название' },
    ],
    updating: false,
    exerciseEditorDialogShow: false,
    exerciseFromTempalteDialogShow: false,
  }),

  computed: {
    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    scountApi() {
      if (!this.mp)
        return null;

      return scountApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },
  },

  methods: {
    async tableUpdated(pagination, sorting, search) {
      await this.getExercises(pagination, sorting, search);
    },

    async getExercises(pagination, sorting, search) {
      if (!this.scountApi) return;

      this.updating = true;

      try {
        const response = await this.scountApi.getExercises(
          pagination,
          sorting,
          search,
        );

        const {values, total} = response.data;

        this.total = total;
        this.items = values;
      } finally {
        this.updating = false;
      }
    },

    async createExercise(model) {
      var exerciseId = uuid.v4();

      const request = {
        title: model.title,
        shortDescription: model.shortDescription,
        description: model.description,
        type: model.type,
        complexity: model.complexity,
        startDate: Moment(model.startDate, 'DD.MM.YYYY hh:mm').toDate(),
        endDate: Moment(model.endDate, 'DD.MM.YYYY hh:mm').toDate(),
        isAutomaticAssign: model.isAutomaticAssign,
        timeLimit: +model.timeLimit || null

      };

      const response = await this.scountApi.createExercise(exerciseId, request);
      
      if (response.success) {
        this.goToExercise(exerciseId);
      } else {
        const e = response.error;
        const errorText = !e
          ? 'Произошла ошибка'
          : `Код статуса ответа: ${e.status}`
            + `<br>Код: ${e.code}`
            + `<br>Ошибка: ${e.title}`
            + `<br>Подробности: ${e.detail}`;
        this.openDialog('Ошибка при создании шаблона', errorText);
      }
    },

    async createExerciseFromTemplate(model) {
      for (let i = 0; i < model.length; i++) {
        const exerciseId = uuid.v4();
        const request = {
          exerciseTemplateId: model[i],
          exerciseId: exerciseId,
        };
        await this.scountApi.createExerciseFromTemplate(exerciseId, request);
      }
      this.goToExercises();
    },

    tableRowClicked(exercise) {
      this.goToExercise(exercise.exerciseId);
    },

    goToExercise(exerciseId) {
      this.$router.push(`/exercises/${exerciseId}`);
    },

    goToExercises() {
      this.$router.push('/exercises');
    },

    getType(type) {
      switch (type) {
        case 'Disposable': return { text: 'Одноразовое', icon: 'mdi-autorenew-off', color: '' };
        case 'Reusable': return { text: 'Многоразовое', icon: 'mdi-autorenew', color: 'success' };
        case 'Regular': return { text: 'Регулярное', icon: 'mdi-regex', color: 'blue' };
        default: return { text: 'undefined' };
      }
    },

    openDialog(title, text) {
      this.dialog.title = title;
      this.dialog.text = text;
      this.dialog.opened = true;
    },

    closeDialog() {
      this.dialog.opened = false;
    },
  },
}
</script>

<style scoped>
.exercise-id {
  color: #999;
  font-size: 80%;
}
</style>