<template>
  <div>
    <v-row>
      <v-col>
        <v-btn @click="exerciseEditorDialogShow = true">Редактировать</v-btn>
      </v-col>
    </v-row>

    <div v-if="exercise">
    <v-row>
      <v-col>
        <p><b>Название задания</b></p>
        <p>{{exercise.title}}</p>
      </v-col>
    </v-row>
    </div>

    <v-row>
      <v-col>
        <v-card
          v-if="exercise"
          :loading="loading"
          width="500"
        >

          <template slot="progress">
            <v-progress-linear
              color="deep-purple"
              height="10"
              indeterminate
            ></v-progress-linear>
          </template>

          <v-img
            :src="exercise.images.desktop"
            style="position:relative;"
          >
            <v-chip label color="white" style="position:absolute;bottom:10px;left:10px;">
              Сложность:
              <span
                class="complexity"
                :class="getComplexityClass(exercise.complexity)"
              >
                {{ exercise.complexity }}
              </span>
            </v-chip>
          </v-img>

          <v-card-title>{{ exercise.title }}</v-card-title>

          <v-card-text>
            <div>{{exercise.shortDescription}}</div>

            <v-row class="mx-0 my-3">
              <v-icon class="mr-2" color="success">mdi-bitcoin</v-icon> asdf
            </v-row>

            <div class="my-3 text-subtitle-1"><b>О задании</b></div>
            <div class="mb-5">{{exercise.description}}</div>
          </v-card-text>

          <v-card-actions>
            <v-btn
              v-if="exercise.state !== 'Published'"
              color="success"
              text
              @click="publishExercise"
              :disabled="isDisabledPublish()"
            >
              <span style="text-align: left">
                Опубликовать              
                <span v-if="isDisabledPublish()" style="text-transform: lowercase; font-weight: normal"><br />дата завершения задания должна быть будущей</span>
              </span>
            </v-btn>
            <v-btn
              v-if="exercise.state === 'Published'"
              color="#b00"
              text
              @click="unpublishExercise"
            >
              Снять с публикации
            </v-btn>
          </v-card-actions>

        </v-card>
      </v-col>

      <!-- 
        <v-col>
          <p><b class="mr-2">ID:</b> {{ exerciseId }}</p>
          <v-switch
            label="Назначать автоматически"
            v-model="exercise.isAutomaticAssign"
            disabled
          ></v-switch>
        </v-col> 
      -->
    </v-row>

    <ExerciseTasksView v-if="exercise" :exercise-type="exercise.type" />

    <ExerciseEditorDialog
      v-if="exerciseEditorDialogShow"
      :object-id="exerciseId"
      :object="exercise"
      :save-func="changeExercise"
      @closed="exerciseEditorDialogShow = false"
    />

    <v-dialog v-model="dialog.opened" width="720">
      <v-card>
        <v-card-title class="headline">{{ dialog.title }}</v-card-title>
        <v-card-text v-html="dialog.text">
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeDialog">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import scountApiInitializer from '@/api/scountApi';
import ExerciseTasksView from './ExerciseTasksView.vue';
import ExerciseEditorDialog from './ExerciseEditorDialog.vue';
import Moment from 'moment'

export default {
  name: 'ExerciseView',
  components: { ExerciseTasksView, ExerciseEditorDialog },

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    return { marketingProgramStore }
  },

  data: () => ({
    exercise: null,
    dialog: {
      opened: false,
      title: undefined,
      text: undefined
    },
    loading: false,
    exerciseEditorDialogShow: false,
  }),

  computed: {
    exerciseId() {
      return this.$route.params.exerciseId;
    },

    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    scountApi() {
      if (!this.mp)
        return null;

      return scountApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },
  },

  methods: {
    isDisabledPublish() {
      return !this.exercise || Moment(this.exercise.endDate, 'DD.MM.YYYY hh:mm').toDate() < new Date()
    },

    async getExercise() {
      const response = await this.scountApi.getExercise(this.exerciseId);
      this.exercise = response.data;

      if (this.exercise.startDate)
        this.exercise.startDate = Moment.utc(this.exercise.startDate).local().format('DD.MM.YYYY HH:mm');
      if (this.exercise.endDate)
        this.exercise.endDate = Moment.utc(this.exercise.endDate).local().format('DD.MM.YYYY HH:mm');
    },

    async changeExercise(model) {
      const request = {
        title: model.title,
        shortDescription: model.shortDescription,
        description: model.description,
        type: model.type,
        complexity: model.complexity,
        startDate: Moment(model.startDate, 'DD.MM.YYYY hh:mm').toDate(),
        endDate: Moment(model.endDate, 'DD.MM.YYYY hh:mm').toDate(),
        isAutomaticAssign: model.isAutomaticAssign,
        timeLimit: +model.timeLimit || null
      };

      await this.scountApi.changeExercise(this.exerciseId, request);

      if (model.desktopImageFile) {
        const destResp = await this.scountApi.uploadDesktopImage(this.exerciseId, model.desktopImageFile);
        if (destResp.success) {
          if (model.mobileImageFile) {
            const mobileResp = await this.scountApi.uploadMobileImage(this.exerciseId, model.mobileImageFile);
            if (!mobileResp.success) {
                this.showError('Ошибка при загрузке изображения mobile', this.getUploadAfterSaveText(mobileResp));
              }
          }
        } else {
          this.showError('Ошибка при загрузке изображения desktop', this.getUploadAfterSaveText(destResp));
        }
      }

      await this.getExercise();
    },

    getUploadAfterSaveText(resp) {
        const e = resp.error;
        if (!e) return 'Произошла ошибка';
        if (e.status === 413) return 'Фото не было загружено. Превышен максимальный размер изображения';
        if (e.status === 400 && e.responseData && e.responseData.errors && e.responseData.errors.File) {
          const errFile = e.responseData.errors.File;
          const max = errFile.find(x => x.startsWith('Maximum '));
          if (max)
            return 'Фото не было загружено. Превышен максимальный размер изображения.';
        }

        return e.detail;
    },

    showError(title, text) {
      this.dialog.title = title;
      this.dialog.text = text;
      this.dialog.opened = true;
    },

    hideError() {
      this.dialog.title = undefined;
      this.dialog.text = undefined;
      this.dialog.opened = false;
    },

    async publishExercise() {
      if (!this.exerciseId || !this.exercise) {
        this.openDialog('Невозможно опубликовать задание', 'Не определено задание');
        return;
      }

      if (!this.exercise || !this.exercise.images || !this.exercise.images.desktop || !this.exercise.images.mobile) {
        this.openDialog('Невозможно опубликовать задание', 'Сначала необходимо добавить изображения');
        return;
      }

      const tasksResponse = await this.scountApi.getExerciseTasks(this.exerciseId);
      if (!tasksResponse || !tasksResponse.data || !(tasksResponse.data.length > 0)) {
        this.openDialog('Невозможно опубликовать задание', 'Сначала необходимо добавить работы');
        return;
      }

      await this.scountApi.publishExercise(this.exerciseId);
      await this.getExercise();
    },

    async unpublishExercise() {
      if (!this.exerciseId || !this.exercise) {
        this.openDialog('Невозможно снять с публикации задание', 'Не определено задание');
        return;
      }

      await this.scountApi.unPublishExercise(this.exerciseId);
      await this.getExercise();
    },

    getComplexityClass(complexity) {
      if (complexity < 1.4)
        return '__low';
      if (complexity < 1.9)
        return '__medium';
      return '__high';
    },

    openDialog(title, text) {
      this.dialog.title = title;
      this.dialog.text = text;
      this.dialog.opened = true;
    },

    closeDialog() {
      this.dialog.opened = false;
    },
  },

  async mounted() {
    this.loading = true;
    try {
      await this.getExercise();
    } finally {
      this.loading = false;
    }
  },
}
</script>

<style lang="scss">
.complexity {
  display: inline-block;
  margin-left: 8px;
  padding: 0 4px;
  border-radius: 4px;
  &.__low {
    background-color: #93de64;
  }
  &.__medium {
    background-color: #ffd666;
  }
  &.__high {
    background-color: #fa4646;
    color: white;
  }
}
</style>
