<template>

  <div>

    <v-row>
      <v-col>
        <h1 class="px-4">Активность участника</h1>
      </v-col>
      <v-col class="buttons">
        <div class="buttons-block" v-if="!kl.isOrgMp">
          <button v-if="activeParticipantTrackerId && participantState === 'Active'" class="buttons__edit" @click="participantTrainingDialogShow = true"><v-icon>mdi-plus</v-icon>Тренировка</button>
          <button v-if="!activeParticipantTrackerId || participantState !== 'Active'" class="buttons__edit" disabled>Трекер не подключен</button>
          <button class="buttons__edit" @click="participantExercisesDialogShow = true"><v-icon>mdi-plus</v-icon>Задание</button>
        </div>
      </v-col>
    </v-row>

    <DataTable
      controller="inRoute"
      :key="filtersHash"
      :headers="headers"
      :items="items"
      :server-items-length="total"
      sort-by="operationDate"
      :sort-desc="true"
      :loading="updating"
      @update:table-options="tableUpdated"
      @click:row="tableRowClicked"
    >
      <template #filters>
        <v-chip
          v-for="type in filterTypeValues"
          :key="`filter-type-${type.value}`"
          class="ma-2"
          label
          small
          :color="filters.operationTypes.includes(type.value) ? 'primary' : ''"
          @click="toggleTypeFilter(type.value)"
        >
          {{type.text}}
        </v-chip>
      </template>

      <template v-slot:[`item.operationType`]="{item}">
        {{ getOperationTypeText(item) }}
      </template>
      <template v-slot:[`item.operationInfo`]="{item}">
        <component v-bind:is="`OperationInfo${getOperationInfoComponentName(item)}`"
          :operationSourceId="item.operationSourceId" :sourceId="item.sourceId" :attributes="item.attributes" :refresh="refreshData">
        </component>
      </template>
      <template v-slot:[`item.operationDate`]="{item}">
        {{ item.operationDate | formatDateTimeRus }}
      </template>
      <template v-slot:[`item.actions`]="{item}">
        <v-icon @click="cancelExercise(item)" 
          hint="Отменить"
          v-bind:style="{ opacity: cancelExerciseLoading ? .5 : 'inherit' }"
          v-if="!kl.isOrgMp && item.operationType === 'ParticipantExercise' && item.attributes.StateOperation === 'Created' && item.attributes.WasRejected !== 'True'">mdi-close-box</v-icon>
        &nbsp;
      </template>
    </DataTable>

    <ParticipantTrainingEditorDialog
      v-if="participantTrainingDialogShow"
      :tracker-id="activeParticipantTrackerId"
      :save-func="saveParticipantTraining"
      @closed="participantTrainingDialogShow = false"
    />

    <ParticipantExercisesEditorDialog
      v-if="participantExercisesDialogShow"
      :participant-id="participantId"
      :save-func="saveParticipantExercises"
      @closed="participantExercisesDialogShow = false"
    />

    <v-dialog v-model="dialog.opened" width="720">
      <v-card>
        <v-card-title class="headline">{{ dialog.title }}</v-card-title>
        <v-card-text v-html="dialog.text">
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeDialog">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { uuid } from "vue-uuid";
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import { useKeycloakStore } from '@/stores/keycloakStore';
import scountApiInitializer from '@/api/scountApi';
import sportDataApiInitializer from '@/api/sportDataApi';
import operationsHistoryApiInitializer from '@/api/operationsHistoryApi';
import OperationInfoAchievement from './OperationInfo/OperationInfoAchievement.vue';
import OperationInfoBalanceOperationRefill from './OperationInfo/OperationInfoBalanceOperationRefill.vue';
import OperationInfoBalanceOperationWithdrawal from './OperationInfo/OperationInfoBalanceOperationWithdrawal.vue';
import OperationInfoNominationParticipant from './OperationInfo/OperationInfoNominationParticipant.vue';
import OperationInfoParticipantExerciseRejected from './OperationInfo/OperationInfoParticipantExerciseRejected.vue';
import OperationInfoParticipantExerciseRejectedManually from './OperationInfo/OperationInfoParticipantExerciseRejected.vue';
import OperationInfoParticipantExerciseCompleted from './OperationInfo/OperationInfoParticipantExerciseCompleted.vue';
import OperationInfoParticipantExerciseCreated from './OperationInfo/OperationInfoParticipantExerciseCreated.vue';
import OperationInfoParticipantExperience from './OperationInfo/OperationInfoParticipantExperience.vue';
import OperationInfoParticipantExperienceLevel from './OperationInfo/OperationInfoParticipantExperienceLevel.vue';
import OperationInfoPurchase from './OperationInfo/OperationInfoPurchase.vue';
import OperationInfoSportActivity from './OperationInfo/OperationInfoSportActivity.vue';
import OperationInfoTeamParticipant from './OperationInfo/OperationInfoTeamParticipant.vue';
import ParticipantTrainingEditorDialog from './ParticipantTrainingEditorDialog.vue';
import ParticipantExercisesEditorDialog from './ParticipantExercisesEditorDialog.vue';
import Moment from 'moment'
import OperationInfoParticipantSteps from './OperationInfo/OperationStepParticipant.vue';

export default {
  name: 'ParticipantOperationsHistoryList',
  components: {
    OperationInfoAchievement,
    OperationInfoBalanceOperationRefill,
    OperationInfoBalanceOperationWithdrawal,
    OperationInfoNominationParticipant,
    OperationInfoParticipantExerciseCompleted,
    OperationInfoParticipantExerciseRejected,
    OperationInfoParticipantExerciseRejectedManually,
    OperationInfoParticipantExerciseCreated,
    OperationInfoParticipantExperience,
    OperationInfoParticipantExperienceLevel,
    OperationInfoPurchase,
    OperationInfoSportActivity,
    ParticipantTrainingEditorDialog,
    ParticipantExercisesEditorDialog,
    OperationInfoTeamParticipant,
    OperationInfoParticipantSteps,
  },

  props: {
    participantId: {
      type: String,
    },
    participantState: {
      type: String,
    },
    saveFunc: Function,
  },

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    const keycloakStore = useKeycloakStore()
    return { marketingProgramStore, keycloakStore }
  },

  data: () => ({
    headers: [
      { value: 'operationType', text: 'Тип', align: 'start', sortable: false },
      { value: 'operationInfo', text: 'Операция', align: 'start', sortable: false },
      { value: 'operationDate', text: 'Дата', align: 'end', sortable: true },
      { value: 'actions', text: 'Действия', align: 'center', width: 120, sortable: false },
    ],
    items: [],
    total: 0,
    selectedTypes: [],
    filters: {
      operationTypes: []
    },
    filtersHash: Date.now(),
    filterTypeValues: [
      {text: 'Задания', value: 'ParticipantExercise'},
      {text: 'Тренировки', value: 'SportActivity'},
      {text: 'Достижения', value: 'Achievement'},
      {text: 'Опыт', value: 'ParticipantExperience'},
      {text: 'Уровни', value: 'ParticipantExperienceLevel'},
      {text: 'Баланс', value: 'BalanceOperation'},
      {text: 'Покупки', value: 'Purchase'},
      {text: 'Очки рейтинга', value: 'NominationParticipant'},
    ],
    updating: false,
    activeParticipantTrackerId: undefined,
    participantTrainingDialogShow: false,
    participantExercisesDialogShow: false,
    pagination: undefined,
    sorting: undefined,
    cancelExerciseLoading: false,
    dialog: {
      opened: false,
      title: undefined,
      text: undefined
    }
  }),

  computed: {
    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    kl() {
      return this.keycloakStore;
    },

    scountApi() {
      if (!this.mp)
        return null;

      return scountApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },

    sportDataApi() {
      if (!this.mp)
        return null;

      return sportDataApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },

    operationsHistoryApi() {
      if (!this.mp)
        return null;

      return operationsHistoryApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    }
  },

  methods: {
    async cancelExercise(item) {
      if (this.cancelExerciseLoading)
        return;
      
      this.cancelExerciseLoading = true;
      const resp = await this.scountApi.cancelParticipantExercise(item.operationSourceId);
      if (resp.success) {
        const ths = this;
        setTimeout(() => {
          ths.cancelExerciseLoading = false;
          ths.refreshData();
        }, 2000);
      }
    },

    async refreshData() {
      await this.updateData(this.pagination, this.sorting);
    },

    async updateData(pagination, sorting) {
      await this.getOperations(pagination, sorting, {
        operationTypes: this.filters.operationTypes.join(',')
      });
    },

    async tableUpdated(pagination, sorting) {
      this.pagination = pagination;
      this.sorting = sorting;
      await this.updateData(pagination, sorting);
    },

    async getOperations(pagination, sorting, filters) {
      if (!this.operationsHistoryApi) return;

      this.updating = true;

      try {
        const response = await this.operationsHistoryApi.getOperations(
          this.participantId,
          pagination,
          sorting,
          filters,
        );

        const {values, total} = response.data;

        this.total = total;
        this.items = values;
      } finally {
        this.updating = false;
      }
    },

    async saveParticipantTraining(model) {
      this.updating = true;

      try {
        const trackerId = model.trackerId;

        const averagePace = model.timeInSeconds / (0.001 * model.distance);
        const averageSpeed = 0.001 * model.distance / (model.timeInSeconds / 3600);

        const request = {
          activityId: uuid.v4(),
          externalId: uuid.v4(),
          discipline: model.discipline,
          startDate: Moment(model.startDate, 'DD.MM.YYYY hh:mm').toDate(),
          finishDate: Moment(model.startDate, 'DD.MM.YYYY hh:mm').add(model.timeInSeconds, "seconds").toDate(),
          distance: model.distance,
          timeInSeconds: model.timeInSeconds,
          averagePace: averagePace,
          averageSpeed: averageSpeed,
        };
        
        const response = await this.sportDataApi.addParticipantTraining(trackerId, request);

        if (response.success) {
            setTimeout(() => {
              this.filtersHash = Date.now();
              this.updating = false;
            }, 3000);
          }
          else {
            const e = response.error;
            const errorText = !e
              ? 'Произошла ошибка'
              : `Код статуса ответа: ${e.status}`
                + `<br>Код: ${e.code}`
                + `<br>Ошибка: ${e.title}`
                + `<br>Подробности: ${e.detail}`;

            this.openDialog('Ошибка при добавлении тренировки', errorText); 
            this.updating = false;
          }
      } catch {
        this.updating = false;
      }
    },

    async saveParticipantExercises(model) {
      this.updating = true;

      try {
        for (let i = 0; i < model.length; i++) {
          const participantExerciseId = uuid.v4();
          const response = await this.scountApi.addParticipantExercise(participantExerciseId, this.participantId, model[i]);
          if (response.success) {
            setTimeout(() => {
              this.filtersHash = Date.now();
              this.updating = false;
            }, 1000);
          } else {
            const e = response.error;
            const errorText = !e
              ? 'Произошла ошибка'
              : `Код статуса ответа: ${e.status}`
                + `<br>Код: ${e.code}`
                + `<br>Ошибка: ${e.title}`
                + `<br>Подробности: ${e.detail}`;

            this.openDialog('Ошибка при добавлении задания', errorText); 
            this.updating = false;
          }
        }
      } catch {
        this.updating = false;
      }
    },

    openDialog(title, text) {
      this.dialog.title = title;
      this.dialog.text = text;
      this.dialog.opened = true;
    },

    closeDialog() {
      this.dialog.opened = false;
    },

    toggleTypeFilter(type) {
      this.filters.operationTypes.includes(type)
        ? this.filters.operationTypes.splice(this.filters.operationTypes.indexOf(type), 1)
        : this.filters.operationTypes.push(type);

      this.filtersHash = Date.now();
    },

    getOperationTypeText(operation) {
      switch (operation.operationType) {
        case 'ParticipantSteps': return 'Зачислены баллы';
        case 'TeamParticipant':
          if (operation.attributes.StateOperation === 'Created') {
            return 'Вступил в команду';
          } else return 'Вышел из команды'
        case 'Achievement': return 'Новое достижение';
        case 'BalanceOperation': return operation.attributes.OperationType === 'Refill' ? 'Зачисление': 'Списание';
        case 'ParticipantExercise':
          if (operation.attributes.StateOperation === 'Created') {
            return 'Новое задание';
          } else if (operation.attributes.StateOperation === 'RejectedManually' && operation.attributes.IsRejectedByAdmin === "True") {
            return 'Задание отменено администратором';
          } else if (operation.attributes.StateOperation === 'RejectedManually' && operation.attributes.IsRejectedByAdmin !== "True") {
            return 'Задание отменено пользователем';
          } else {
            return 'Задание выполнено';
          }
        case 'ParticipantExperience': return 'Получен опыт';
        case 'ParticipantExperienceLevel': return 'Новый уровень';
        case 'Purchase': return 'Покупка';
        case 'SportActivity': return 'Тренировка';
        case 'NominationParticipant': return 'Рейтинг изменен';
      }
    },

    getOperationInfoComponentName(operation) {
      switch (operation.operationType) {
        case 'TeamParticipant': return operation.operationType;
        case 'BalanceOperation': return operation.operationType + operation.attributes.OperationType;
        case 'ParticipantExercise': return operation.operationType + operation.attributes.StateOperation;
        default: return operation.operationType;
      }
    },

    tableRowClicked() {
      // this.goToParticipant(participant.participantId);
    },

    goToParticipant(participantId) {
      this.$router.push(`/participants/${participantId}`);
    },

    async getActiveParticipantTracker() {
        const response = await this.sportDataApi.getActiveParticipantTrackerId(this.participantId);
        this.activeParticipantTrackerId = response.data;
    }
  },

  async mounted() {
    if (!this.filters.operationTypes) {
      // this.setStateFilter({text: 'Все', value: 'All'});
      this.setStateFilter({text: 'Достижения', value: 'Achievement'});
    }

    await this.getActiveParticipantTracker();
  },
}
</script>

<style lang="scss" scoped>
  @import '../../assets/global';
</style>
