<template>
  <div>
    <v-row class="px-4">
      <v-col cols="4">
        <v-autocomplete
        label="Фильтр по маркетинговой программе"
        :items="marketingPrograms3"
        item-text="title"
        item-value="marketingProgramId"
        v-model="marketingProgramId"
        @change="handleMarketingProgramChange"
        :clearable="marketingProgramId !== '' && marketingPrograms3.length !== 1"
        @click:clear="resetMarketingProgram"
        no-data-text="Ничего не найдено"
        />
      </v-col>
      <v-col cols="4">
        <v-select
          label="Фильтр по статусу"
          :items="promocodeState"
          v-model="state"
          @change="handleMarketingProgramChange"
          :clearable="state !== ''"
          @click:clear="resetState"

        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="promotion"
          label="Фильтр по поощрению"
          @click="promotionDialogOpen"
          @input="handleMarketingProgramChange"
          :clearable="promotion !== ''"
          @click:clear="resetPromotion"
          readonly
        />
      </v-col>
    </v-row>
    <!-- <pre>{{ marketingPrograms }}</pre> -->
    <DataTable 
      controller="inRoute"
      :headers="headers" 
      :items="items" 
      :server-items-length="totalPromocode"
      search-title="Найти промокод" 
      :search-fields="searchFields" 
      sort-by="createdOn"
      :sort-desc="false"
      :loading="updating"
      @update:table-options="updateTable"
      show-select
      item-key="promocodeId"
      v-model="selected"
      >
      <template #actions>
        <v-btn large  @click="promocodeCreateDialogOpen">Добавить промокод</v-btn>
        <v-btn large @click="newParticipantsCsvOpen" class="ml-3">
          <v-icon left>
            mdi-content-save
          </v-icon>
            Импорт промокодов
          </v-btn>
      </template>
      <template v-slot:[`item.code`]="{item}">
        <div>{{ item.code }}</div>
        <div class="promocode-id">ID: {{ item.promocodeId }}</div>
      </template>
      <template v-slot:[`item.promotion`]="{ item }">
        <div>{{ item.promotion ? item.promotion.name : '' }}</div>
      </template>
      <template v-slot:[`item.marketingProgram`]="{ item }">
        <div>{{ item.marketingProgram ? item.marketingProgram.title : '-'}}</div>
      </template>
      <template v-slot:[`item.createdOn`]="{ item }">
        <div>{{ item.createdOn ? formatDate(item.createdOn)  : '-'}}</div>
      </template>
      <template v-slot:[`item.actions`]="{item}">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon 
              class="mr-2" 
              icon
              v-on="on"
              @click="confirmDialogShow(item)"
              v-if="item.state === 'Создан'"
              >
              mdi-trash-can-outline
            </v-icon>
          </template>
          <span>Удалить</span>
        </v-tooltip>
          <v-icon 
            class="mr-2" 
            icon
            color="#ccc"
            v-if="item.state === 'Забронирован' || item.state === 'Куплен'"
            >
            mdi-trash-can-outline
          </v-icon>
        </template>
      <template v-slot:footer>
        <div class="py-4" v-if="items.length > 0">
          <v-btn dark color="red" @click="deletePromocodeSelected">Удалить выбранные</v-btn>
        </div>
      </template>
    </DataTable>
    <v-dialog
      v-model="confirmDialog"
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h6">
          Вы уверены что хотите удалить промокод?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="confirmDialog = false"
          >
            Отменить
          </v-btn>

          <v-btn
            color="red"
            dark
            @click="deletePromocodeItem"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="newParticipantsCsv"
      max-width="500"
      @input="handlePromocodeCreateDialogClose"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Добавление промокода
        </v-card-title>
        <v-form
          v-model="valid"
          class="pa-6 pb-0"
          ref="form1"
          lazy-validation
        >
          <v-row>
            <v-col cols="12">
              <v-file-input
                label="Файл с промокодами (.csv)"
                :rules="validation.promotionCreate"
                dense
                accept="csv"
                @change="uploadImportFile($event)"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="importData.promotion"
                :rules="validation.promotionCreate"
                label="Поощрение"
                @click="promotionDialogOpen"
                readonly
                dense
              />
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="importData.marketingProgram"
                :rules="validation.marketingProgramIdCreate"
                label="Маркетинговая программа"
                :items="marketingPrograms2"
                item-text="title"
                item-value="marketingProgramId"
                dense
              />
            </v-col>
          </v-row>
        </v-form>
        <v-card-actions class="pa-6">
          <v-spacer></v-spacer>
          <v-btn @click="newParticipantsCsvDialogClose">Отмена</v-btn>
          <v-btn
            color="#FF8233"
            :dark="valid"
            :disabled="!valid"
            @click="importPromocodes"
          >
            Импортировать
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="promocodeCreateDialog"
      max-width="600"
      @input="handlePromocodeCreateDialogClose"
      persistent
      >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Добавление промокода
        </v-card-title>
        <v-form
          v-model="valid"
          class="pa-6 pb-0"
          ref="form"
          lazy-validation
        >
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="promocodeValue"
                :rules="validation.promocodeValue"
                label="Значение промокода"
                dense
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="promotionCreate"
                :rules="validation.promotionCreate"
                label="Поощрение"
                @click="promotionDialogOpen"
                readonly
                dense
              />
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="marketingProgramIdCreate"
                :rules="validation.marketingProgramIdCreate"
                label="Маркетинговая программа"
                :items="marketingPrograms2"
                item-text="title"
                item-value="marketingProgramId"
                dense
              />
            </v-col>
            <v-col cols="6">
              <v-checkbox
                v-model="isCountPromocodes"
                label="Многоразовый промокод"
                dense
              ></v-checkbox>
            </v-col>
            <v-col v-if="isCountPromocodes" cols="6">
              <v-text-field
                v-model="promocodeСount"
                :rules="validation.promocodeСount"
                label="Количество штук"
                dense
              /></v-col>
          </v-row>
        </v-form>
        <v-card-actions class="pa-6">
          <v-spacer></v-spacer>
          <v-btn @click="promocodeCreateDialogClose">Отмена</v-btn>
          <v-btn
            color="#FF8233"
            :dark="valid"
            :disabled="!valid"
            @click="createPromocode1"
          >
            Создать
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="promotionDialog"
      max-width="800"
      >
      <v-card class="pa-4">
        <DataTable
          v-model="selected1"
          :headers="headersPromotion"
          :items="promotionItems"
          :server-items-length="total"
          search-title="Найти поощрение"
          :search-fields="searchFields1"
          sort-by="createdOn"
          :sort-desc="true"
          :loading="updating1"
          @update:table-options="updateTablePromotions"
          @click:row="clickPromotion"
          >
          <template v-slot:[`item.code`]="{item}">
            <div>{{ item.code }}</div>
            <div class="promocode-id">ID: {{ item.promotionId }}</div>
          </template>
          <template v-slot:[`item.createdOn`]="{ item }">
            <div>{{ item.createdOn ? formatDate(item.createdOn)  : '-'}}</div>
          </template>
          <template v-slot:[`item.state`]="{ item }">
            <div v-if="item.state">{{ item.state === 'Enabled' ? 'Активен' : 'Неактивен' }}</div>
            <div v-else>-</div>
          </template>
        </DataTable>
      </v-card>
    </v-dialog>
    <notifications :duration="5000" position="bottom right"/>

  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import { useKeycloakStore } from '@/stores/keycloakStore';
  import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
  import moment from 'moment';
  export default {

    setup() {
      const marketingProgramStore = useMarketingProgramStore()
      const keycloakStore = useKeycloakStore()
      return { marketingProgramStore, keycloakStore }
    },

    data: () => ({
      headers: [
        { value: 'code', text: 'Промокод', align: 'start', sortable: true },
        { value: 'promotion', text: '	Поощрение', align: 'start', sortable: true },
        { value: 'marketingProgram', text: 'Маркетинговая программа', align: 'center', sortable: true },
        { value: 'createdOn', text: 'Дата создания', align: 'end', sortable: true },
        { value: 'state', text: 'Статус', align: 'end', sortable: false },
        { value: 'actions', text: 'Действия', align: 'end', sortable: false },
      ],
      headersPromotion: [
        { value: 'name', text: '	Поощрение', align: 'start', sortable: true },
        { value: 'promocodeQty', text: 'Количество промокодов', align: 'center', sortable: false, width: '200px' },
        { value: 'createdOn', text: 'Дата создания', align: 'center', sortable: true },
        { value: 'state', text: 'Статус', align: 'end', sortable: false },
      ],
      promocodeState: [
        {text:'Куплен', value: 'Purchased'},
        {text:'Создан', value: 'Created'},
        {text:'Забронирован', value: 'Holded'},
      ],
      validation: {
        promocodeValue: [
          value => !!value || 'Обязательное поле.',
        ],
        promotionCreate: [
          value => !!value || 'Обязательное поле.',
        ],
        marketingProgramIdCreate: [
          value => !!value || 'Обязательное поле.',
        ],
        promocodeСount: [
          value => {
            if (/^\d+(\.\d{1,2})?$/.test(value)) {
              return parseFloat(value) >= 1 || 'Значение должно быть числом не меньше 1';
            }
            return 'Значение должно быть числом не меньше 1';
          }
        ]
      },
      valid: true,
      items: [],
      selected: [],
      selected1: [],
      updating: false,
      updating1: false,
      searchFields: [
        { key: 'code', label: 'Промокод' },
      ],
      searchFields1: [
        { key: 'name', label: 'Поощрение' },
      ],
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
      pagination1: {
        page: 1,
        itemsPerPage: 10,
      },
      sorting: {
        sortBy: 'createdOn',
        sortDesc: true,
      },
      marketingProgramId: '',
      marketingProgramId2: '',
      marketingProgramIdCreate: '',
      selectedItem: '',
      confirmDialog: false,
      promocodeCreateDialog: false,
      promocodeId: '',
      promocodeValue: '',
      promotionDialog: false,
      promotion: '',
      promotionCreate: '',
      promotionItems: [],
      promotionId: '',
      search: '',
      search1: '',
      state: '',
      created: false,
      selectedPromocodeIds: null,
      stateObject: {
        marketingProgramId: '',
        promotionId: '',
        state: '',
        page: 1,
        sortDesc: true,
      },
      newParticipantsCsv: false,
      importData: {},
      isCountPromocodes: false,
      promocodeСount: 1,
    }),
    methods: {
      ...mapActions("promocodes", ["getPromocodes", "deletePromocode", "deleteSelectedPromocodes", "createPromocode", "importPromocode"]),
      ...mapActions("promotions", ["getMarketingPrograms", "getPromotions"]),
      newParticipantsCsvDialogClose() {
        this.newParticipantsCsv = false;
        this.$refs.form1.reset()
      },
      promocodeCreateDialogClose() {
        this.promocodeCreateDialog = false;
        this.$refs.form.reset()
      },
      uploadImportFile(event) {
        this.importData.file = event;
      },
      updateTable(pagination, sorting, search, marketingProgramId, promotionId, state) {
        this.pagination = pagination;
        this.sorting = sorting;
        this.search = search;
        this.updating = true;
        const requestParams = { pagination, sorting, search, promotionId, state };
        if (marketingProgramId !== "" || promotionId !== '' || state !== '' ) {
          requestParams.marketingProgramId = marketingProgramId;
          requestParams.promotionId = promotionId
          requestParams.state = state
        }
        this.getPromocodes({ pagination, sorting, search, marketingProgramId:this.marketingProgramId, promotionId: this.promotionId, state: this.state, creatorId: this.kl.filterByCreatorId })
        .then(() => {
          this.items = this.promocodes
          const stateMapping = {
            Purchased: "Куплен",
            Created: "Создан",
            Holded: "Забронирован",
          };
          for (let i = 0; i < this.items.length; i += 1) {
            const value = this.items[i];
            value.state = stateMapping[value.state] || "-";
          }
          this.updating = false;
        });
        this.$forceUpdate();
      },
      updateTablePromotions(pagination, sorting, search, marketingProgramId) {
        this.pagination1 = pagination;
        this.sorting1 = sorting;
        this.search1 = search;
        this.updating1 = true;
        const requestParams = { pagination, sorting, search };
        if (marketingProgramId !== "" ) {
          requestParams.marketingProgramId = marketingProgramId;
        }
        this.getPromotions({ pagination, sorting, search, marketingProgramId:this.marketingProgramId2, creatorId: this.kl.filterByCreatorId })
        .then(() => {
          this.promotionItems = this.promotions.values
          this.updating1 = false;
        });
        this.$forceUpdate();
      },
      formatDate(date) {
        return moment(date).format('DD-MM-YYYY HH:MM');
      },
      promocodeCreateDialogOpen(){
        this.promocodeCreateDialog = true;
        this.created = true
      },
      async deletePromocodeItem() {
        try {
          await this.deletePromocode({ promocodeId: this.promocodeId });
          this.confirmDialog = false;
          this.$notify({type: 'success', text: 'Промокод успешно удален'})
          await this.updateTable(this.pagination, this.sorting, this.search);
        } catch (error) {
          alert('Произошла ошибка при удалении промокода.');
        }
      },
      confirmDialogShow(item) {
        this.promocodeId = item.promocodeId
        this.confirmDialog = true
      },
      handleMarketingProgramChange() {
        if (this.created) {
          this.created = false;
        }

        const newQuery = {
          ...this.$route.query,
          marketingProgramId: this.marketingProgramId || undefined,
          promotionId: this.promotionId || undefined,
          state: this.state || undefined,
          page: 1,
          sortDesc: this.stateObject.sortDesc,
        };

        // Проверяем, изменились ли параметры маршрута, кроме страницы
        const isParamsChanged = (
          newQuery.marketingProgramId !== this.stateObject.marketingProgramId ||
          newQuery.promotionId !== this.stateObject.promotionId ||
          newQuery.state !== this.stateObject.state
        );

        // Если параметры маршрута, кроме страницы, изменились, переходим на первую страницу
        if (isParamsChanged) {
          newQuery.page = 1;
        }
        this.$router.push({ ...this.$route, query: newQuery });

        // Обновляем объект состояния
        this.stateObject.marketingProgramId = newQuery.marketingProgramId;
        this.stateObject.promotionId = newQuery.promotionId;
        this.stateObject.state = newQuery.state;
      },
      promotionDialogOpen() {
        this.promotionDialog = true;
      },
      clickPromotion(e) {
        if (!this.created){
          this.created = false
          if (this.newParticipantsCsv) {
            this.importData.promotion = e.name
          } else {
          this.promotion = e.name
          }
          this.promotionId = e.promotionId
          this.promotionDialog = false
        } else {
          this.promotionCreate = e.name
          this.promotionId = e.promotionId
          this.promotionDialog = false
        }
      },
      resetPromotion() {
        this.promotionId = '';
        this.created = false;
        this.updateTable(this.pagination, this.sorting, this.search, this.marketingProgramId, '', this.state);
      },
      resetState() {
        this.state = '';
        this.updateTable(this.pagination, this.sorting, this.search, this.marketingProgramId, this.promotionId, '');
      },
      resetMarketingProgram() {
        this.marketingProgramId = '';
        this.updateTable(this.pagination, this.sorting, this.search, '', this.promotionId, this.state);
      },
      handlePromocodeCreateDialogClose(value) {
        // value равно false, если модальное окно закрыто
        if (!value) {
          this.created = false;
        }
      },
      async createPromocode1() {
        const valid = await this.$refs.form.validate();
        if (!valid) {
          return;
        }
        const requestData = {
          code: this.promocodeValue,
          promotionId: this.promotionId,
          marketingProgramId: this.marketingProgramIdCreate,
          creatorId: this.kl.userId,
          numberOfPromocodeUses: +this.promocodeСount
        };

        try {
          await this.createPromocode(requestData);
          this.promocodeCreateDialog = false;
          this.promocodeValue = '';
          this.promotionCreate = '';
          this.promotionId = '';
          this.promocodeСount = 1;
          this.marketingProgramIdCreate = '';
          this.isCountPromocodes = false;
          this.$notify({type: 'success', text: 'Промокод успешно создан'})
          this.updateTable(this.pagination, this.sorting, this.search, this.marketingProgramId, this.promotionId, this.state);
        } catch (error) {
            alert('Промокод с таким именем уже существует.', error);
        }
      },
      getSelectedPromocodeIds() {
        return this.selected.map((item) => item.promocodeId);
      },
      deletePromocodeSelected() {
        this.selectedPromocodeIds = this.getSelectedPromocodeIds();
        const selectedPromocodes = this.selected.filter((item) => item.state !== 'Куплен' && item.state !== 'Забронирован');

        if (selectedPromocodes.length > 0) {
          this.deleteSelectedPromocodes({ promocodeIds: this.selectedPromocodeIds })
            .then(() => {
              this.updateTable(this.pagination, this.sorting, this.search, this.marketingProgramId, this.promotionId, this.state);
              this.$notify({type: 'success', text: 'Промокод успешно удален'})
              this.selectedPromocodeIds = [];
              this.selected = [];
            })
            .catch((error) => {
              console.error('Ошибка при удалении промокодов:', error);
            });
        } else {
          // alert('Вы не можете удалить промокоды со статусом "Куплен", "Забронирован" или вы не выбрали промокод.');
          this.$notify({type: 'error', text: 'Вы не можете удалить промокоды со статусом "Куплен", "Забронирован" или вы не выбрали промокод.'})

        }
      },
      newParticipantsCsvOpen() {
        this.newParticipantsCsv = true
      },
      async importPromocodes() {
        const valid = await this.$refs.form1.validate();
        if (!valid) {
          return;
        }
        const l = this.importData.file.size;
        let wtMessage;
        if (l >= 50000) wtMessage = 'более 5 минут';
        else {
          const t = Math.floor(l / 10000) + 1;
          wtMessage = `${t} минут${t === 1 ? 'а' : t === 5 ? '' : 'ы'}`;
        }

        this.importPromocode({
          marketingProgramId: this.importData.marketingProgram,
          promotionId: this.promotionId,
          file: this.importData.file,
          creatorId: this.kl.userId
        }).then(() => {
          this.newParticipantsCsv = false;
          this.$notify({type: 'success', text: `Импорт промокодов запущен. Ожидаемое время загрузки промокодов в базу ${wtMessage}`});
        })
        .catch((error) => {
          this.$notify({type: 'error', text: `Ошибка импорта промокодов`});
          console.error('Ошибка при удалении промокодов:', error);
        });
      }
    },
    computed:{
      ...mapGetters("promocodes", ["promocodes", "totalPromocode", "responseStatus", "errorDetail"]),
      ...mapGetters("promotions", ["marketingPrograms", "promotions", "total"]),
      mp() {
        if (this.marketingProgramStore.isInitialized === false)
          return null;

        return this.marketingProgramStore;
      },
      marketingPrograms2() {
        if (this.kl.isAnyMp) {
          return this.mp ? [ { marketingProgramId: this.mp.marketingProgramId, title: this.mp.title }] : [];
        } else {
          return this.marketingPrograms;
        }
      },
      marketingPrograms3() {
        if (this.kl.isAnyMp) {
          return this.mp ? [ { marketingProgramId: this.mp.marketingProgramId, title: this.mp.title }] : [];
        } else {
          return [{ marketingProgramId: '', title: 'Все маркетинговые программы' }].concat(this.marketingPrograms);
        }
      },
      kl() {
        return this.keycloakStore;
      },
    },
    watch: {
      promotion: function (newValue) {
        // Вызываем метод updateTable при изменении значения promotion
        this.updateTable(this.pagination, this.sorting, this.search, this.marketingProgramId, newValue, this.state);
      },    
    },

    async mounted() {
      this.getMarketingPrograms();
      if (this.marketingPrograms3?.length === 1) {
        this.marketingProgramId = this.marketingPrograms3[0].marketingProgramId;
        this.marketingProgramId2 = this.marketingPrograms3[0].marketingProgramId;
      }
    }
  }
</script>

<style lang="scss" scoped>
.promocode-id {
  color: #999;
  font-size: 80%;
}
</style>